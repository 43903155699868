<template>
  <!-- 4月17日完成，开发人员：王世龙 -->
  <div>
    <main>
      <!-- <Header></Header> -->
      <!-- 背景1 -->
      <div class="bg1">
        <img v-lazy="require('./imgs/bg5.png')" alt="" class="bg11" />
        <span class="tybg">智慧办公</span>
        <span class="xc"
          >随着时代进步，社会工作发展也呈现出“快节奏”的趋势，在办公开会<br />方面体现得尤为明显。
        </span>
      </div>
      <!-- 背景1 -->
      <!-- 背景2 -->
      <div class="bg2">
        <span class="qywx1">即时通讯</span>
        <div class="img11">
          <img v-lazy="require('./imgs/img1.png')" class="img1" />
        </div>
        <div class="qywx">
          <img src="./imgs/qywx.png" alt="" class="icon1" />
          <span class="ydd">即时通讯移动端</span>
        </div>
      </div>
      <!-- 背景2 -->
      <!-- 背景3 -->
      <div class="bg3">
        <span class="txhy">远程会议</span>
        <div class="img22">
          <img v-lazy="require('./imgs/img2.png')" class="img2" />
        </div>
        <div class="img33">
          <img v-lazy="require('./imgs/img3.png')" class="img3" />
        </div>
        <div class="qyb">
          <span class="txqyb">远程会议企业版</span>
          <span class="nr"
            >通过多终端智能协作，为客户提供覆盖全球100+国家及地区的云端会议解决方案，开启跨区域多场景的云端沟通协作新体验。</span
          >
        </div>
        <div class="rooms">
          <span class="txrooms">远程会议Rooms</span>
          <span class="nr1"
            >远程会议Rooms是专为会议室打造的软件系统，提供音视频会议、无线投屏、触屏式白板的集成会议体验，开放音视频认证标准，臻选品牌硬件终端，共同为企业提供现代化的会议室解决方案。</span
          >
        </div>
      </div>
      <!-- 背景3 -->
      <!-- 背景4 -->
      <div class="bg4">
        <span class="wxtp">无线投屏</span>
        <div class="img44">
          <img v-lazy="require('./imgs/img4.png')" class="img4" />
        </div>
        <!-- <img src="./imgs/img5.png" alt="" class="img5">
        <div class="lhcp">联合出品</div> -->
      </div>
      <!-- 背景4 -->
      <Header></Header>
      <Footer></Footer>
    </main>
  </div>
</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
  padding: 0 !important;
}
.bg1 {
  height: 600px;
  // min-width: 1920px;
  // background-image: url(./imgs/bg5.png);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
  .bg11 {
    height: 600px;
  }
  .tybg {
    min-width: 230px;
    height: 67px;
    font-size: 48px;
    font-weight: 500;
    color: #ffffff;
    line-height: 67px;
    letter-spacing: 3px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 230px;
    left: 360px;
    text-align: left;
  }
  .xc {
    min-width: 1205px;
    height: 66px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    line-height: 33px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5), 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 317px;
    left: 360px;
    text-align: left;
  }
}
.bg2 {
  height: 656px;
  // min-width: 1920px;
  position: relative;
  .qywx1 {
    min-width: 167px;
    height: 56px;
    font-size: 40px;
    font-weight: 400;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
    position: absolute;
    top: 80px;
    left: 878px;
  }
  .img11 {
    width: 800px;
    // height: 360px;
    position: absolute;
    top: 196px;
    left: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 360px;
      width: 100%;
    }
    .img1 {
      width: 800px;
      height: 19.25vw;
    }
  }
  .qywx {
    min-width: 400px;
    height: 360px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 196px;
    left: 1160px;
    .icon1 {
      width: 104px;
      height: 104px;
      position: absolute;
      top: 99px;
      left: 147px;
    }
    .ydd {
      min-width: 181px;
      height: 33px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
      letter-spacing: 1px;
      position: absolute;
      top: 277px;
      left: 109px;
    }
  }
}
.bg3 {
  // min-width: 1920px;
  height: 993px;
  background: #f6f8fb;
  position: relative;
  .txhy {
    min-width: 200px;
    height: 56px;
    font-size: 40px;
    font-weight: 400;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 860px;
  }
  .img22 {
    width: 580px;
    height: 420px;
    position: absolute;
    top: 196px;
    left: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 420px;
      width: 100%;
    }
    .img2 {
      width: 580px;

      // border: 1px dashed black;
    }
  }
  .img33 {
    width: 560px;
    height: 420px;
    position: absolute;
    top: 196px;
    left: 975px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 420px;
      width: 100%;
    }
    .img3 {
      width: 560px;
    }
  }
  .qyb {
    min-width: 579px;
    height: 272px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    left: 360px;
    top: 616px;
    .txqyb {
      min-width: 300px;
      height: 45px;
      font-size: 32px;
      font-weight: 400;
      color: #007aff;
      line-height: 45px;
      letter-spacing: 1px;
      position: absolute;
      top: 36px;
      left: 1px;
    }
    .nr {
      width: 513px;
      height: 108px;
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 97px;
      left: 36px;
      text-align: left;
    }
  }
  .rooms {
    min-width: 29.167vw;
    height: 272px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    left: 975px;
    top: 616px;
    .txrooms {
      min-width: 350px;
      height: 45px;
      font-size: 32px;
      font-weight: 400;
      color: #007aff;
      line-height: 45px;
      position: absolute;
      top: 36px;
      left: -24px;
    }
    .nr1 {
      width: 513px;
      height: 144px;
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 97px;
      left: 34px;
      text-align: left;
    }
  }
}
.bg4 {
  height: 863px;
  // min-width: 1920px;
  background: #ffffff;
  position: relative;
  .wxtp {
    min-width: 167px;
    height: 56px;
    font-size: 40px;
    font-weight: 400;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
    position: absolute;
    top: 80px;
    left: 878px;
  }
  .img44 {
    width: 1228px;
    height: 500px;

    position: absolute;
    top: 168px;
    left: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 500px;
      width: 100%;
    }
    .img4 {
      width: 1228px;
    }
  }
  .img5 {
    min-width: 22.438vw;
    height: 55px;
    position: absolute;
    top: 716px;
    left: 711px;
  }
  .lhcp {
    min-width: 132px;
    height: 45px;
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    line-height: 45px;
    position: absolute;
    top: 38vw;
    left: 61.24vw;
  }
}
</style>
